<script lang="ts" setup>
  import { ref, watch } from 'vue';
  import { useInterval } from '@vueuse/core';
  import { useQueryClient } from '@tanstack/vue-query';
  import {
    useNotificationsQuery,
    invalidateNotifications,
    useNotificationsCountQuery,
    invalidateNotificationsCount,
  } from '~/composables/query/notifications';
  import VNotificationsWidgetButton from './components/VNotificationsWidgetButton.vue';
  import VNotificationsWidgetList from './components/VNotificationsWidgetList.vue';

  const queryClient = useQueryClient();
  const { data: notifications } = useNotificationsQuery();

  const { data: countData } = useNotificationsCountQuery();

  const menu = ref();

  const interval = useInterval(20_000); // 20 seconds

  watch(
    () => interval.value,
    () => {
      invalidateNotifications(queryClient);
      invalidateNotificationsCount(queryClient);
    },
  );

  const toggle = (event: Event) => {
    menu.value.root.toggle(event);
  };
</script>

<template>
  <div
    v-if="notifications"
    class="relative"
    data-testid="root"
  >
    <VNotificationsWidgetButton
      :count="countData?.countNew"
      @click="toggle"
    />
    <VNotificationsWidgetList
      ref="menu"
      :notifications="notifications"
    />
  </div>
</template>
